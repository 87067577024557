<template>
  <Layout tituloPagina="Sistema | Cuenta | Cambiar de plan Hotspot">
    <div class="row">
      <div class="col-lg-3" v-show="estado_modulo == true">
        <div class="card">
          <div class="card-header bg-success justify-content-between d-flex align-items-center">
            <h4 class="card-title text-white">Plan Hotspot actual</h4>
            <div>
              <span class="badge bg-info bg-gradient">Activo</span>
            </div>
          </div>
          <div class="card-body" style="min-height: 509px;">
            <h1 class="text-center">
              <strong>Plan {{plan_hotspot_contratado.nombre}}</strong>
            </h1>
            <br>
            <table class="table">
              <tbody class="text-center">
                <tr>
                  <td style="width:50%;">Hotspot</td>
                  <td>{{plan_hotspot_contratado.max_hotspots != -1 ? 'hasta '+plan_hotspot_contratado.max_hotspots : 'Ilimitado'}}</td>
                </tr>
                <tr>
                  <td>Fichas</td>
                  <td>{{plan_hotspot_contratado.max_fichas != -1 ? 'hasta '+plan_hotspot_contratado.max_fichas : 'Ilimitado'}}</td>
                </tr>
                <tr>
                  <td>Routers</td>
                  <td>{{plan_hotspot_contratado.max_routers != -1 ? 'hasta '+plan_hotspot_contratado.max_routers : 'Ilimitado'}}</td>
                </tr>
                <tr>
                  <td>Notificación de routers de administración caídos</td>
                  <td>{{plan_hotspot_contratado.notificacion_routers_caidos ? 'SI' : 'NO'}}</td>
                </tr>
                <tr>
                  <td>Impresión de fichas</td>
                  <td>SI</td>
                </tr>
                <tr>
                  <td>Diseño de fichas</td>
                  <td>{{plan_hotspot_contratado.layout_fichas ? 'SI' : 'NO'}}</td>
                </tr>
                <tr>
                  <td>Generación de fichas desde cualquier lugar</td>
                  <td>SI</td>
                </tr>
                <tr>
                  <td>Eliminación de fichas vencidas en los routers.</td>
                  <td>SI</td>
                </tr>
                <tr>
                  <td>Precio mensual</td>
                  <td>${{plan_hotspot_contratado.precio_mensual}} USD</td>
                </tr>
                <tr>
                  <td>Precio anual</td>
                  <td>
                    ${{plan_hotspot_contratado.precio_anual}} USD <br>
                    <strong>{{costoAnualPocentaje(plan_hotspot_contratado.precio_mensual, plan_hotspot_contratado.precio_anual)}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <p>
                <span class="h3">
                  <strong>Precio ${{plan_hotspot_contratado.precio_mensual}} USD</strong>
                </span><br>
                Costo mensual
              </p>
            </div>
            <br>
            <div class="text-center">
              <button
                class="btn w-200"
                :class="estado_modulo != true ? 'btn-success' : 'btn-danger'"
                @click="actDescEstModulo()"
              >
                <i class="fa" :class="estado_modulo != true ? 'mdi-check-bold' : 'mdi-cancel'"></i>
                {{estado_modulo != true ? 'Activar modulo' : 'Desactivado modulo'}}
              </button>
            </div>
            <hr>
            <div class="text-center">
              <p><strong>Perido actual: {{formatoFecha(ultima_suscripcion.inicio)}} a {{formatoFecha(ultima_suscripcion.fin)}}</strong></p>
            </div>
          </div>
        </div>
      </div>
      <div :class="estado_modulo == true ? 'col-lg-9' : 'col-lg-12'">
        <div class="row">
          <template v-for="plan_hotspot in planes_hotspots">
            <div :key="plan_hotspot.id" class="col-md-3" v-if="true">
              <div class="card">
                <div class="card-header bg-info justify-content-between d-flex align-items-center">
                  <h4 class="card-title text-white">{{plan_hotspot.nombre}}</h4>
                  <div>
                    <i class="mdi mdi-bookmark fa-lg text-white"></i>
                  </div>
                </div>
                <div class="card-body">
                  <h5 class="text-center">
                    <strong>Caracteristicas del plan</strong>
                  </h5>
                  <br>
                  <table class="table">
                    <tbody class="text-center">
                      <tr>
                        <td style="width:50%;">Hotspot</td>
                        <td>{{plan_hotspot.max_hotspots != -1 ? 'hasta '+plan_hotspot.max_hotspots : 'Ilimitado'}}</td>
                      </tr>
                      <tr>
                        <td>Fichas</td>
                        <td>{{plan_hotspot.max_fichas != -1 ? 'hasta '+plan_hotspot.max_fichas : 'Ilimitado'}}</td>
                      </tr>
                      <tr>
                        <td>Routers</td>
                        <td>{{plan_hotspot.max_routers != -1 ? 'hasta '+plan_hotspot.max_routers : 'Ilimitado'}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-center">
                    <p>
                      <span class="h4">
                        <strong>Precio ${{plan_hotspot.precio_mensual}} USD</strong>
                      </span><br>
                      Costo mensual
                    </p>
                  </div>
                  <br>
                  <div class="text-center">
                    <p>
                      <span class="h4">
                        <strong>Precio ${{plan_hotspot.precio_anual}} USD</strong>
                      </span><br>
                      {{costoAnualPocentaje(plan_hotspot.precio_mensual, plan_hotspot.precio_anual)}}
                    </p>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn btn-outline-link text-success"
                      @click="caracteristicashotspot(plan_hotspot.id)">
                      Ver todas las caracteristicas
                    </button>
                  </div>
                  <br>
                  <router-link :to="{ name: 'actualizarPlanHotspot', params:{ id_plan: plan_hotspot.id} }">
                    <div class="p-2 m-0 rounded text-center bg-soft-dark">
                      <span class="h4">
                        <strong>
                          {{estado_modulo ? 'Cambiar' : 'Activar'}} plan hotspot
                        </strong>
                      </span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="text-right pb-4" style="min-width: 220px;">
      <button class="btn btn-secondary" @click="$router.go(-1)">
        <i class="mdi mdi-chevron-left"></i>
        Atras
      </button>
    </div>
    <mdl-caracteristicas-hotspot ref="mdlCaracteristicasHotspot"></mdl-caracteristicas-hotspot>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import MdlCaracteristicasHotspot from "./MdlCaracteristicasHotspot.vue"
import CuentaSrv from "@/services/CuentaSrv.js"
import moment from 'moment'

export default {
  name: 'ModulosHotspot',
  components: { Layout, MdlCaracteristicasHotspot },
  data() {
    return {
      estado_modulo: false,

      plan_hotspot_contratado: {
        id: null,
        descripcion: "",
        layout_fichas: 0,
        layout_portal_cautivo: 0, 
        max_fichas: 0,
        max_hotspots: 0,
        max_routers: 0,
        nombre: "", 
        notificacion_routers_caidos: 0, 
        precio_anual: 0,
        precio_mensual: 0
      },

      planes_hotspots: [],

       ultima_suscripcion: {
        estado: 0,
        fin: "",
        id: null,
        id_cliente: null,
        id_empresa: null,
        id_plan_hotspot: null,
        id_plan_inventario: null,
        id_plan_isp: null,
        id_plan_vpn: null,
        inicio: "",
        renovar: 0,
        tipo_suscripcion: 0
      }
    }
  },
  created: function() {
    var self = this

    self.cargarEstado()
    self.refrescarPlanesHotspots()
  },
  methods: {
    actDescEstModulo: function() {
      var self = this

      if(self.estado_modulo != 1) {
        CuentaSrv.activarModulo('hotspot').then(response => {
          iu.msg.success('Modulo hotspot activado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo activar el modulo hotspot'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      } else {
        CuentaSrv.desactivarModulo('hotspot').then(response => {
          iu.msg.success('Modulo hotspot desactivado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo desactivar el modulo hotspot'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      }
    },
    
    caracteristicashotspot: function(idPlan) {
      this.$refs.mdlCaracteristicasHotspot.mostrar(idPlan, "planes", this.estado_modulo)
    },

    cargarEstado: function() {
      var self = this

      CuentaSrv.estadoModulo('hotspot').then(response => {
        self.estado_modulo = response.data

        if(self.estado_modulo == true) self.cargarPlanContratado()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlanContratado: function (){
      var self = this

      CuentaSrv.moduloPlanContratadoJSON('hotspot').then(response => {
        self.plan_hotspot_contratado = response.data

        self.suscripcion()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    costoAnualPocentaje: function(precio_mensual, precio_anual){
      if(precio_anual < 0) return ''

      let total = precio_mensual * 12
      let porcentaje = ((total - precio_anual) * 100) / total

      if(porcentaje < 0) return ''

      return `Costo anual (incluye ${parseInt(porcentaje)}% de descuento)`
    },

    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    },

    refrescarPlanesHotspots: function() {
      var self = this

      CuentaSrv.moduloPlanesDisponiblesJSON('hotspot').then(response => {
        self.planes_hotspots = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'no se puedo cargar los planes hotspot disponibles'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    suscripcion() {
      var self = this

      CuentaSrv.suscripcionJSON().then(response => {
        self.ultima_suscripcion = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>